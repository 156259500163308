<app-widget-container>
  <form>
    <mat-action-list>
      <mat-list-item (click)="useNewObjectPageToggle.toggle()" [disabled]="useNewMainMenuFC.value">
        <span matListItemTitle class="feature-title">{{ 'TRANS__FEATURE_FLAGS_WIDGET__NEW_OBJECT_PAGE_TITLE' | translate }}</span>
        <span matListItemLine class="feature-description">{{ 'TRANS__FEATURE_FLAGS_WIDGET__NEW_OBJECT_PAGE_DESCRIPTION' | translate }}</span>
        <mat-slide-toggle (click)="$event.stopPropagation()" matListItemMeta #useNewObjectPageToggle [formControl]="useNewObjectPageFC"></mat-slide-toggle>
      </mat-list-item>
      <mat-list-item (click)="useNewFilterMenu.toggle()" [disabled]="useNewMainMenuFC.value">
        <span matListItemTitle class="feature-title">{{ 'TRANS__FEATURE_FLAGS_WIDGET__NEW_FILTER_MENU_TITLE' | translate }}</span>
        <span matListItemLine class="feature-description">{{ 'TRANS__FEATURE_FLAGS_WIDGET__NEW_FILTER_MENU_DESCRIPTION' | translate }}</span>
        <mat-slide-toggle (click)="$event.stopPropagation()" matListItemMeta #useNewFilterMenu [formControl]="useNewFilterMenuFC"></mat-slide-toggle>
      </mat-list-item>
      <!--<mat-list-item (click)="toggleNewLayout()">
        <span matListItemTitle class="feature-title">{{ 'TRANS__FEATURE_FLAGS_WIDGET__NEW_MAIN_MENU_TITLE' | translate }}</span>
        <span matListItemLine class="feature-description">{{ 'TRANS__FEATURE_FLAGS_WIDGET__NEW_MAIN_MENU_DESCRIPTION' | translate }}</span>
        <mat-slide-toggle (click)="toggleNewLayout()" matListItemMeta [formControl]="useNewMainMenuFC"></mat-slide-toggle>
      </mat-list-item>-->
    </mat-action-list>
  </form>
</app-widget-container>
